@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Microgramma';
  src: url('/public/fonts/microgrammanormal.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body { height: 100%; }

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-logo {
  font-family: 'Microgramma', sans-serif;
  color: #083319;
}

.focus-ring {
  @apply focus:outline-none focus:ring-2 focus:ring-blue-500;
}